import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/sobre",
    name: "sobre",
    meta: {
      title: "Sobre",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/produtos",
    name: "produtos",
    meta: {
      title: "Produtos",
    },
    component: () => import("../views/Products.vue"),
  },
  {
    path: "/produto",
    name: "produto",
    meta: {
      title: "Produto",
    },
    component: () => import("../views/ProductPage.vue"),
  },
  {
    path: "/contatos",
    name: "contatos",
    meta: {
      title: "Contatos",
    },
    component: () => import("../views/Contacts.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(e) {
    console.log(e);
    if (e.name == "home") {
      return { el: ".banner-carousel", top: 500 };
    } else {
      return { el: "#main", top: 160 };
    }
  },
});

router.afterEach((router) => {
  if (router.meta.title) {
    document.title = `${router.meta.title} | Z Foods`;
  } else {
    document.title = "Z Foods";
  }
});

export default router;
