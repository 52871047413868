<script setup>
const props = defineProps({
  to: String,
  href: Boolean,
  label: String,
  primary: Boolean,
  secondary: Boolean,
  type: String,
});

const button = props.href ? "a" : "input";

if (props.href) {
  button.value = "a";
}
</script>

<template>
  <component
    v-if="!to"
    :is="button"
    :class="['btn', { '-primary': primary }]"
    v-text="label"
    :type="type"
    :value="label"
  />
  <RouterLink
    v-if="to"
    :to="to"
    :class="['btn', { '-primary': primary }]"
    v-text="label"
  />
</template>

<style scoped lang="scss">
.btn {
  @include button-shape;

  text-transform: uppercase;

  &.-primary {
    background-color: var(--primary);
    color: #fff;
  }
}
</style>
