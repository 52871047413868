<script setup>
import BaseNav from "./Nav.vue";
import Icon from "./snippets/Icon.vue";
import Container from "./snippets/Container.vue";
import { ref } from "vue";

const isOpen = ref(false);

if (window.innerWidth > 769) {
  isOpen.value = true;
}

const toggleMenu = () => {
  isOpen.value = !isOpen.value;
};

const closeMenu = () => {
  if (window.innerWidth < 769) {
    isOpen.value = false;
  }
};
</script>

<template>
  <header class="header">
    <Container lg>
      <RouterLink to="/">
        <div class="logo">
          <img src="@/assets/icons/logo.svg" alt="Z Foods" class="logo-img" />
          <h2>Foods</h2>
        </div>
      </RouterLink>
      <div class="menu">
        <BaseNav v-if="isOpen" @click="closeMenu" />
        <div class="socials">
          <a href="https://facebook.com" target="_blank">
            <Icon name="facebook-f" iconType="brands" />
          </a>
          <a href="https://instagram.com" target="_blank">
            <Icon name="instagram" iconType="brands" />
          </a>
          <a href="#" target="_blank">
            <Icon name="location-dot" iconType="solid" />
          </a>
        </div>
        <Icon name="bars" iconType="solid" @click="toggleMenu" />
      </div>
    </Container>
  </header>
</template>

<style lang="scss">
.header {
  --container-direction: row;
  --container-justify: space-between;

  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  padding: {
    top: 1rem;
    bottom: 1rem;
  }

  .logo {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    column-gap: 0.6rem;
  }

  .logo-img {
    width: 5rem;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6vw;

    .fa-bars {
      @include max($desktop) {
        font-size: 2rem;
      }

      @include min($desktop) {
        display: none;
      }
    }
  }

  .nav {
    gap: 0.8rem;
    display: flex;
    flex-wrap: wrap;

    @include max($desktop) {
      position: absolute;
      top: 6.8rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fff;
      height: calc(95vh - 6.8rem);
      width: 100vw;
      flex-direction: column;
      justify-content: space-around;
      box-shadow: 0 3px 3px var(--grey-200);
    }
  }

  .socials {
    @include max($desktop) {
      display: none !important;
    }

    display: flex;
    align-items: center;
    gap: 1rem;

    .icon {
      color: var(--secondary);
      font-size: 1.25rem;
    }
  }
}
</style>
