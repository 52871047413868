<script setup>
import { ref } from "vue";

const menus = ref([
  {
    nav: "Z Foods",
    link: "/sobre",
  },
  {
    nav: "Produtos",
    link: "/produtos",
  },
  /* {
    nav: "Representantes",
    link: "/representantes",
  },
  {
    nav: "Clientes",
    link: "/clientes",
  }, */
  {
    nav: "Contatos",
    link: "/contatos",
  },
]);

defineProps({
  footer: Boolean,
});
</script>

<template>
  <nav class="nav">
    <RouterLink v-if="footer" to="/" class="menu-link"> Início </RouterLink>
    <RouterLink
      :to="nav.link"
      v-for="nav in menus"
      :key="nav.nav"
      v-html="nav.nav"
      class="menu-link"
    />
  </nav>
</template>

<style lang="scss" scoped>
@import "../scss/mixins/index.scss";

.nav {
  .menu-link {
    @include button-shape;
  }
}
</style>
