<script setup>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import BaseButton from "./snippets/Button.vue";
import { computed } from "vue";

const slides = [
  {
    image: require("@/assets/images/banner-1.jpg"),
    mobileImage: require("@/assets/images/banner-mobile-1.png"),
    text: "Alto padrão de<br><b>qualidade e sabor!</b>",
    to: "sobre",
  },
  {
    image: require("@/assets/images/banner-2.jpg"),
    mobileImage: require("@/assets/images/banner-mobile-2.png"),
    miniTitle: "Você escolhe o preparo:",
    text: "<b>fogo, grelha<br>ou churrasco!</b>",
    to: "sobre",
  },
  {
    image: require("@/assets/images/banner-3.jpg"),
    mobileImage: require("@/assets/images/banner-mobile-3.png"),
    text: "<b>Angus:</b><br>a carne mais nobre<br> direto para a sua mesa.",
    to: "sobre",
  },
  {
    image: require("@/assets/images/banner-4.jpg"),
    mobileImage: require("@/assets/images/banner-mobile-4.png"),
    text: "O frango também<br><b>tem espaço!</b>",
    to: "sobre",
  },
];

const isMobile = computed(() => {
  if (window.innerWidth < 769) {
    return true;
  } else return false;
});
</script>

<template>
  <div class="banner-carousel">
    <Splide
      class="slide-carousel"
      :options="{
        arrows: false,
        pagination: true,
        autoplay: true,
        interval: 5000,
        speed: 1500,
        width: '100vw',
        rewind: true,
        rewindSpeed: 1500,
        breakpoints: {
          1023: {
            pagination: false,
          },
        },
      }"
    >
      <SplideSlide class="slide-item" v-for="slide in slides" :key="slide">
        <img v-if="isMobile" :src="slide.mobileImage" class="slide-image" />
        <img v-else :src="slide.image" class="slide-image" />
        <div class="slide-content">
          <div class="title">
            <h4
              v-if="slide.miniTitle"
              v-text="slide.miniTitle"
              class="mini-title"
            />
            <h1 v-html="slide.text" />
          </div>
          <BaseButton :to="slide.to" label="Ver mais" primary class="btn" />
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>

<style lang="scss">
.banner-carousel {
  .slide-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(var(--section-spacing) / 2);
    color: #fff;

    @include max($desktop) {
      width: 90%;
      top: 25%;
      transform: translate(-50%, -25%);
      left: 50%;
    }

    @include min($desktop) {
      top: 50%;
      transform: translateY(-50%);
      left: 17vw;
    }

    .mini-title {
      color: var(--secondary);
    }

    .btn {
      @include button-shape;
      height: 2.5rem;
      padding: {
        right: 2rem;
        left: 2rem;
      }
    }
  }
}
</style>
